class FunnelIframe {

    funnel;

    constructor() {
        this.initIframe();
    }

    initIframe() {
        if (this.funnelExists()) {
            this.getFunnel();
            window.addEventListener('message', message => this.handleMessage(message), false);
        }
    }

    funnelExists() {
        return document.getElementById('funnel') != null;
    }

    getFunnel() {
        return this.funnel = document.getElementById('funnel');
    }

    sendMessage(action, data) {
        const message = {
            type: 'SHIPMENT_LIGHT_API',
            action,
            data
        };

        this.funnel.contentWindow.postMessage(message, '*')
    }

    handleMessage(message) {
        const ghostContainer = document.getElementById('ghost-container');

        if (message.data && message.data.type === 'SHIPPING_APP' && message.data.source === 'FUNNEL_LIGHT') {
            //console.log('Host recieved message : ', message.data);

            if (message.data.action === 'READY') {
                ghostContainer.classList.add('hidden');
                this.funnel.classList.add('visible');
                this.sendMessage('SET_LOCALE', 'fr-FR');
                this.funnel.classList.remove('hidden');
            } else if (message.data.action === 'HEIGHT_CHANGED') {
                this.funnel.style.height = message.data.data + 'px';
            } else if (message.data.action === 'SUBMIT') {
                window.location = message.data.data;
            }
        } else if (message.data && message.data.type === 'SHIPPING_APP' && message.data.source === 'CRASH_SERVICE') {
            // console.log('OUPS : ', message.data);
            const tunnelScope = document.getElementById('funnel-scope');
            tunnelScope.classList.add('oops');
        }
    }
}

export function initFunnelIframe() {
    return new FunnelIframe();
}

